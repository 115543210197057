import {Injectable} from '@angular/core';
import {HttpWellnessSendService} from 'src/app/wellness-send/http-wellness-send.service';
import {ParamsServiceService} from 'src/app/wellness-send/params-service.service';
import {Router} from '@angular/router';
import {AuthService} from '../../core/auth/auth.service';
import {of} from 'rxjs';
import {finalize, take} from 'rxjs/operators';
import {MatchList} from '../../shared/dao/in/MatchList';

@Injectable({
  providedIn: 'root'
})
export class WrpeService {
  rpe: number;
  fillableRPEs: MatchList[];
  choosenRPE: number;
  public loading = false;

  constructor(
    public httpWellnessSendService: HttpWellnessSendService,
    public paramsService: ParamsServiceService,
    public router: Router,
    public auth: AuthService
  ) {
  }


  send() {
    this.loading = true;
    if (this.auth.isAdmin() || this.auth.isTrainer()) {
      return this.httpWellnessSendService.rpeSend(
        this.paramsService.playerId,
        this.choosenRPE,
        this.rpe
      ).pipe(finalize(() => this.loading = false)).subscribe(value => {
        this.choosenRPE = null;
        this.rpe = null;
        this.router.navigate(['/well/wmain']);
      });
    }
    if (this.auth.isPlayer()) {
      return this.httpWellnessSendService.rpeSendPlayer(
        this.choosenRPE,
        this.rpe
      ).pipe(finalize(() => this.loading = false)).subscribe(value => {
        this.choosenRPE = null;
        this.rpe = null;
        this.router.navigate(['/well/wmain']);
      });
    }
    return of(null);
  }
}
